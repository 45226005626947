<template>
	<main id="container" class="page-container mypage">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="마이페이지" />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="mypage-tab-container">
				<div class="container button-wrap">
					<!-- <button classs="mypage-tab-button is-active"> -->
					<button :class="sortCd == 'F' ? 'mypage-tab-button is-active' : 'mypage-tab-button'" @click="showPage('F')">
						<span>찜한제품</span>
					</button>
					<!-- <button classs="mypage-tab-button is-active"> -->
					<button :class="sortCd == 'S' ? 'mypage-tab-button is-active' : 'mypage-tab-button'" @click="showPage('S')">
						<span>별점내역</span>
					</button>
				</div>
			</div>
			<div class="container">
				<!-- 찜한제품 페이지 -->
				<div class="mypage-content" v-bind:class="{ active: sortCd === 'F'}">
					<div class="mypage-content-header">
						<p>
							<span class="mypage-name">{{ session.userNm }}</span> 님이 찜한 제품은
							<span class="mypage-total boldtext">{{ likeData.prdTotal }}개</span>이며,
							총 금액은 <span class="mypage-summ boldtext">{{ Number(likeData.prdSumm).toLocaleString() }}</span>원입니다.
						</p>
					</div>
					<div class="products-list">
						<products-item v-for="(item, idx) in favrManprdList" :key="item.manprdId" :id="item.id" :item="item" type="F" 
							parent-like-evnt-nm="likeClicked" @likeClicked="handleClickLike" parent-click-evnt-nm="modalClicked" @modalClicked="handleClickModal" />
					</div>
				</div>
				<!-- 별점내역 페이지 -->
				<div class="mypage-content" v-bind:class="{ active: sortCd === 'S'}">
					<div class="mypage-content-header">
						<p>
							<span class="mypage-name">{{ session.userNm }}</span> 님이 평가한 제품은
							<span class="mypage-total boldtext">{{ rateData.prdTotal }}개</span>이며,
							평균 별점은 <span class="mypage-rate boldtext">{{ rateData.prdScore }}</span>입니다.
						</p>
					</div>
					<div class="products-list">
						<products-item v-for="(item, idx) in occtManprdList" :key="item.manprdId" :id="item.id" :item="item" type="S" 
							parent-like-evnt-nm="likeClicked" @likeClicked="handleClickLike" parent-click-evnt-nm="modalClicked" @modalClicked="handleClickModal" />
					</div>
				</div>				
			</div>
		</div>
		<products-modal :visible="visibleModal" @closeModal="visibleModal = false" :item="modalItem" parent-click-evnt-nm="clickEvnt" @clickEvnt="handleClickModalEvnt"/>
		<div class="pagination-wrap">
			<portal-pagination page-name="Category" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="totalCount" :query="queries" :parent-method-name="pagingMethodName" @getManprdList="getManprdList"></portal-pagination>
		</div>
	</main>
</template>

<script>
import PageHeader from '@/components/content/PageHeader';
import ProductsItem from '@/components/content/ProductsItem';
import PortalPagination from '@/components/PortalPaginationRoute';
import ProductsModal from '@/components/content/ProductsModal';
import { ACT_GET_CAMPN_MY_MANPRD_LIST } from '@/store/_act_consts';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_CONFIRM } from '@/store/_mut_consts';
import { getCheckItems,lengthCheck, tryResCallback, setPaging, timestampToDateFormat } from '@/assets/js/utils';
import { mapGetters } from 'vuex';
export default {
	name: 'MyPage',
	components: { PageHeader, ProductsItem, ProductsModal, PortalPagination},
	data: () => ({
		name: '',
		likeData: {
			prdTotal: 0,
			prdSumm: 0,
		},
		rateData: {
			prdTotal: 0,
			prdScore: 0,
		},
		sortCd: 'F',
		//동행제품
		pagingMethodName: 'getManprdList',
		pageNo: 1,
		pageSize: 10,
		totalCount: 0,
		pageListSize: 10,
		visibleModal: false,
		modalId: 0, 
		modalType: "",
		favrManprdList: [],
		occtManprdList: [],
	}),
	computed: {
		...mapGetters('common', ['commonCode', 'campn']),
		...mapGetters('auth', ['session', 'isAuth']),
		modalItem() {
			return (this.sortCd === "S" ? this.occtManprdList.find(i => i.id == this.modalId) : this.favrManprdList.find(i => i.id == this.modalId));
		},
		queries() {
			const result = {};
			result.searchName = this.searchName;
			result.ctgrDcd = this.ctgrDcd;
			return result;
		},
	},
	created() {
		if (!this.chkLogin('로그인 페이지로 이동합니다.')) return false;
		this.getManprdList(this.pageNo, this.sortCd);
	},
	mounted() {},
	methods: {
		showPage(sortCd) {
			if(this.sortCd === sortCd) return false;
			this.pageNo = 1;
			this.sortCd = sortCd;
			this.getManprdList(this.pageNo, sortCd);
		
		},
		//  F: 찜순, S: 별점순 -- 상품 노출 영역
		getManprdList(pageNo, sortCd) {
			this.pageNo = pageNo;
			this.manprdList = [];
			if(typeof sortCd == "undefined") sortCd = this.sortCd;
			// api 호출
			this.$store
				.dispatch(`manprd/${ACT_GET_CAMPN_MY_MANPRD_LIST}`, {
					campnId: this.campn.campnId,
					sortCd: sortCd,
					pageNo: this.pageNo,
					pageSize: this.pageSize,
				})
				.then((res) => {
					res.sortCd = sortCd;
					tryResCallback(this.tryCount, res, this.getManprdList, this.setManprdList);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		setManprdList(res) {
			this.occtManprdList = [];
			this.favrManprdList = [];
			if (lengthCheck(res)) {
				if(res.sortCd === "F"){
					this.favrManprdList = getCheckItems(res).map((i) => {
						return {
							id: i.manprdId,
							image: i.manprdImgFileUrl,
							company: i.brndNm,
							title: i.manprdNm,
							price: i.manprdPrc,
							rate: i.socreAvg,
							rateCount: i.scoreCnt,
							like: i.favrYn == 'Y',
							likeCount: i.favrCnt,
							myRate: i.myScore,
							desc: i.manprdDesc,
							siteUrl: i.ntslSiteUrl,
							isEng : false,
							useCampnDurYn: i.useCampnDurYn == 'Y',
							ntslStrtDt: timestampToDateFormat(i.ntslStrtDt, 'yyyy-MM-dd'),
							ntslEndDt: timestampToDateFormat(i.ntslEndDt, 'yyyy-MM-dd')
						};
					});
				} else if(res.sortCd === "S"){
					this.occtManprdList = getCheckItems(res).map((i) => {
						return {
							id: i.manprdId,
							image: i.manprdImgFileUrl,
							company: i.brndNm,
							title: i.manprdNm,
							price: i.manprdPrc,
							rate: i.socreAvg,
							rateCount: i.scoreCnt,
							like: i.favrYn == 'Y',
							likeCount: i.favrCnt,
							myRate: i.myScore,
							desc: i.manprdDesc,
							siteUrl: i.ntslSiteUrl,
							isEng : false,
							useCampnDurYn: i.useCampnDurYn == 'Y',
							ntslStrtDt: timestampToDateFormat(i.ntslStrtDt, 'yyyy-MM-dd'),
							ntslEndDt: timestampToDateFormat(i.ntslEndDt, 'yyyy-MM-dd')
						};
					});
				}
				setPaging(this, res);
			}else{
				this.totalCount = 0;
			}
			if(res.sortCd === "F") {
				this.likeData.prdTotal = this.favrManprdList.length;
				this.likeData.prdSumm = this.favrManprdList.reduce((total, b) => total + b.price, 0);
			}else {
				this.rateData.prdTotal = this.occtManprdList.length;
				if(this.occtManprdList.length > 0) this.rateData.prdScore = Math.round((this.occtManprdList.reduce((total, b) => total + b.myRate, 0) / this.occtManprdList.length) * 10) / 10;
				else this.rateData.prdScore = 0;
			}
			
		},
		// 찜하기
		handleClickLike(idx, type) {
			this.getManprdList(this.pageNo, type);
		},
		handleClickModal(idx, type) {
			this.modalId = idx;
			this.visibleModal = true;
		},
		handleClickModalEvnt(type, score) {
			this.getManprdList(this.pageNo, this.sortCd);
		},
		handleClickCtgr(dcd) {
			this.ctgrDcd = dcd;
			this.getManprdList(1);
		},
		chkLogin(title) {
			if (!this.isAuth) {
				const param = {
					title: title,
					html: true,
					showCancelButton: false,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
					nofunc: () => {},
				};
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
				return false;
			}

			return true;
		},
	},
};
</script>
